import { useEffect } from 'react';
import './styles/default.scss'
import { useState } from 'preact/hooks';
import { MDS } from '@fanduel/casino-swj-consumer';
import { useSelector } from 'react-redux';
import { swjTiersSelector } from '../../../selectors/site-wide-jackpot';
import { getMDSEndpoint } from '../../../common/helpers/get-mds-endpoint';

const defaultValue = {
    userId: "163694",
    messageId: "messageid_1",
    type: "JACKPOT",
    subtype: "WIN",
    createdTs: 1720696033021,
    priority: 0,
    eventTtl: 86400,
    jackpotTierName: "MajorJackpot",
    jackpotTierId: "asd",
    amount: 50,
}

const EmptyGame = () => {
    const userId = useSelector(state => state?.session?.userId);
    const [swjInput, setSWJInput] = useState(defaultValue);
    const [messages, setMessages] = useState([]);
    const swjTiers = useSelector(swjTiersSelector);
    const { emitMockJackpotWin, initSocket } = MDS(getMDSEndpoint(), setMessages);

    const handleInputChange = (e) => {
        setSWJInput(prev => {
            if (!e.target.value) {
                return { ...prev, [e.target.name]: defaultValue[e.target.name] };
            }

            return { ...prev, [e.target.name]: e.target.value };
        });
    }

    const emitWin = () => {
        const { jackpotTierId, jackpotTierName, amount, ...rest } = swjInput;

        const obj = {
            ...rest,
            payload: JSON.stringify({
                jackpotTierName,
                jackpotTierId,
                amount,
                jackpotId: 'random'
            })
        }

        emitMockJackpotWin(obj);
    };

    useEffect(() => {
        defaultValue.jackpotTierId = Object.keys(swjTiers)[0];
    }, [swjTiers])

    useEffect(() => {
        if (!userId) {
            return;
        }

        initSocket(userId);

        window.top.postMessage({ type: 'GC/SET_GAME_LOADED', payload: false }, '*')
    },[userId]);

    useEffect(() => {
        console.log({ messages });
    }, [messages]);

    const spinSimulate = () => {
        spinStart();
        setTimeout(() => {
            spinEnd();
        }, 3000);
    };

    const spinStart = () => {
        window.top.postMessage({ type: 'GC/SET_SPIN_IN_PROGRESS', payload: true }, '*')
    };

    const spinEnd = () => {
        window.top.postMessage({ type: 'GC/SET_SPIN_IN_PROGRESS', payload: false }, '*')
    };

    return <div className="wrapper">
        <button onClick={() => spinStart()}> Spin START</button>
        <button onClick={() => spinEnd()}> Spin FINISHED</button>
        <button onClick={() => spinSimulate()}> Spin (3 seconds)</button>
        <button onClick={() => emitWin()}>Emit jackpot win</button>
        <div id="jackpot-payload">
            {Object.keys(swjInput).map(key => <div key={key}>{key}: {<input name={key} onChange={handleInputChange} defaultValue={defaultValue[key]} />}</div>)}
        </div>
    </div>
}

export default EmptyGame;