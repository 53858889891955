import { createSelector } from 'reselect';
import { gameWindowConfigSelector, variantSelector } from './variants';

export const showGameWindowSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.show_game_window
);

export const showLogoSelector = createSelector(gameWindowConfigSelector, config => config?.primary?.show_logo);

export const showSoundButtonSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.show_sound_button
);

export const showExitButtonSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.show_exit_button
);

export const showDepositButtonSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.show_deposit_button
);

export const showWalletSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.show_total_balance
);

export const depositLinkSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.deposit_link?.url
);

export const supportLinkSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.support_link?.url
);

export const depositLimitsLinkSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.set_deposit_limits_link?.url
);

export const showMenuButtonSelector = createSelector(gameWindowConfigSelector, config => config?.primary?.show_menu);

export const showResponsibleGamingSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.show_responsible_gaming
);

export const responsibleGamingLinkSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.responsible_gaming_link?.url
);

export const gameHelpItemsSelector = createSelector(variantSelector, variant => {
    const gameHelpSlice = variant?.find(elem => elem?.slice_type === 'game_help');

    return gameHelpSlice?.items ?? [];
});

const gameWindowState = state => state?.gameWindow;
const geocomplyState = state => state?.geocomply;

export const sessionTimeSelector = createSelector(gameWindowState, gameWindow => gameWindow?.sessionTime);

export const gameTimeSelector = createSelector(gameWindowState, gameWindow => gameWindow?.gameTime);

export const currentTimeSelector = createSelector(gameWindowState, gameWindow => gameWindow?.currentTime);

export const licenseCardVisibleSelector = createSelector(gameWindowState, gameWindow => gameWindow?.licenseCardVisible);

export const errorMessageDisplayedSelector = createSelector(
    gameWindowState,
    gameWindow => gameWindow?.errorMessageDisplayed
);

export const inGameErrorSelector = createSelector(gameWindowState, gameWindow => gameWindow?.inGameError);

export const gameLoadedSelector = createSelector(gameWindowState, gameWindow => gameWindow?.gameLoaded);

export const licenseNumbersSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.license_numbers
);

export const licenseRegulatorSelector = createSelector(
    gameWindowConfigSelector,
    config => config?.primary?.license_regulator
);

export const gameInfoSelector = createSelector(gameWindowState, gameWindow => gameWindow?.gameWindowUI?.gameInfo);

export const gameWindowUILoadingSelector = createSelector(
    gameWindowState,
    gameWindow => gameWindow?.gameWindowUILoading
);

export const loadingPercentSelector = createSelector(gameWindowState, gameWindow => gameWindow?.loadingPercent);

export const loadingDataSelector = createSelector(gameWindowState, gameWindow => gameWindow?.gameWindowUI?.gameInfo?.game_loading_screen);

export const themeDataSelector = createSelector(gameWindowState, gameWindow => gameWindow?.gameWindowUI?.gameInfo?.game_loading_screen?.data?.body?.[0]?.items?.[0]);

export const gameProviderNameSelector = createSelector(gameWindowState, gameWindow => gameWindow?.gameWindowUI?.gameInfo?.provider?.providerName);

export const countryCodeSelector = createSelector(geocomplyState, geocomplyState => geocomplyState?.location?.details?.country_code);

export const gameLabelSelector = createSelector(gameWindowState, gameWindow => gameWindow?.gameWindowUI?.gameInfo?.label);