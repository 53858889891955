import { Fragment } from 'preact';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import {
    swjGameRulesSelector,
    swjOneLinerSelector,
    swjTCTextSelector
} from '../../../../../selectors/site-wide-jackpot';
import { redirectToBrowser } from '../../../../../common/wrapper-bridge-mobile';
import { isMobile } from 'react-device-detect';
require(`./styles/${process.env.APPLICATION}/style.scss`);

export const TCPopup = ({toggleOptIn, userOptedIn, setShowTCPopup, renderLoadingClass}) => {
    const jackpotRulesDefaultURL = `${window.location.origin.replace('launcher.', '')}/site-wide-jackpot-rules`;
    const jackpotRulesURL = useSelector(swjGameRulesSelector);
    const optedInOption = userOptedIn > 0 ? 'in' : 'out';
    const oneLiner = useSelector(swjOneLinerSelector);
    const tcDescription = useSelector(swjTCTextSelector);

    const handleOptInButton = () => {
        toggleOptIn();
    };

    const handleJackpotRulesLink = (e) => {
       if (!!isMobile && !!jackpotRulesURL) {
            e.preventDefault();
            redirectToBrowser(jackpotRulesURL);
        }
    }

    return (
        <Fragment>
            {createPortal(
                <div className="tc-background" onClick={() => setShowTCPopup(false)} />,
                document.getElementById("game-launcher-wrapper")
            )}
            <div className="tc-wrapper">
                <div className="header">
                    <div className="jackpots-chyron" />
                </div>

                <div className="content">
                    <span className="title">{oneLiner}</span>
                    <div className="description">
                        {tcDescription}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={(!!jackpotRulesURL && !!isMobile) ? jackpotRulesURL : jackpotRulesDefaultURL}
                            onClick={handleJackpotRulesLink}>
                            Jackpot Rules
                        </a>
                    </div>
                </div>

                <div className={`footer ${renderLoadingClass(false)}`}>
                    <div className="button-field">
                        <span className={`text-opted-${optedInOption}`}>{`You're opted ${optedInOption}`}</span>
                            <input type="checkbox" id="switch-button" checked={userOptedIn} onChange={handleOptInButton} />
                            <label for="switch-button" />
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
