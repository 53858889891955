import axios, { AxiosInstance } from "axios";

import { JackpotFeedResponse } from "./types";

let httpClient: AxiosInstance;

const getJackpotFeed = async (provider: string): Promise<JackpotFeedResponse[]> => {
  const res = await httpClient.get("/jackpot/api/v2/jackpots", {
    headers: {
      "x-provider-id": provider
    }
  });

  if (res.status >= 200 && res.status < 300) {
    return res.data as JackpotFeedResponse[];
  }

  return [];
};

const jackpotFeed = async (
  host: string,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  method: (arg: JackpotFeedResponse[]) => void = () => {},
  persistValue = false,
  timeout = 10000,
  { provider }: {
    provider: string,
  }
) => {
  httpClient = axios.create({
    baseURL: host
  });

  const interval = setInterval(async () => {
    method(await getJackpotFeed(provider));
  }, timeout);

  if (!persistValue) {
    clearInterval(interval);
  }

  const result = await getJackpotFeed(provider);
  method(result);

  return result;
};

export default jackpotFeed;
