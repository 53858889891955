import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type JackpotWinType = {
    id: string,
    shortName: string,
    name: string,
    amount: number,
}

interface SiteWideJackpotState {
    jackpotData: object,
    jackpotWin: JackpotWinType[] | null | undefined,
    loading: boolean,
    error: boolean,
}

const initialState: SiteWideJackpotState = {
    jackpotData: undefined,
    jackpotWin: [],
    loading: false,
    error: false
};

export const siteWideJackpotSlice = createSlice({
    name: 'siteWideJackpot',
    initialState,
    reducers: {
        setJackpotData: (state, action: PayloadAction<object>) => {
            state.jackpotData = action.payload;
        },
        setJackpotWin: (state, action: PayloadAction<JackpotWinType>) => {
            state.jackpotWin.push(action.payload);
        },
        settleJackpotWin: (state) => {
            state.jackpotWin.shift();
        },
    }
});

export const { setJackpotData, setJackpotWin, settleJackpotWin } = siteWideJackpotSlice.actions;

const siteWideJackpotReducer = siteWideJackpotSlice.reducer;
export default siteWideJackpotReducer;
