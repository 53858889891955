import { createSelector } from 'reselect';

const siteWideJackpotAggregatedSelector = state => state?.siteWideJackpot?.jackpotData;

export const swjDataSelector = createSelector(siteWideJackpotAggregatedSelector, aggregatedData => {
    return aggregatedData;
})

const swjTextsSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.body?.[0]?.primary;
})

export const swjOneLinerSelector = createSelector(swjTextsSelector, texts => {
    return texts?.in_game_one_liner?.[0]?.text;
})

export const swjOptedInTextSelector = createSelector(swjTextsSelector, texts => {
    return texts?.in_game_opted_in_text?.[0]?.text;
});

export const swjOptedOutTextSelector = createSelector(swjTextsSelector, texts => {
    return texts?.in_game_opted_out_text?.[0]?.text;
});

export const swjJackpotIdSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.provider_jackpot_id?.[0]?.text;
});

export const swjEnabledSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.enabled;
});

export const swjTiersSelector = createSelector(swjDataSelector, jackpotData => {
    const rawTiers = jackpotData?.tiers;

    if (!rawTiers) {
        return [];
    }

    return rawTiers.reduce((acc, tier) => {
        return {
            ...acc,
            [tier.tier_id]: {
                name: tier?.tier_name?.[0]?.text,
                shortName: tier?.short_tier_name?.[0]?.text,
                description: tier?.description?.[0]?.text,
                logo: tier?.logo?.url || tier?.logo1?.url,
            }
        }
    }, {})
});

export const swjLogoUrlSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.logo?.url;
});

export const swjNameSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.name?.[0]?.text;
});

export const swjTCTextSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.tc_short_version?.[0]?.text;
});

export const swjGameRulesSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.rules_page_url?.url;
});

export const swjProviderSelector = createSelector(swjDataSelector, jackpotData => {
    return jackpotData?.provider?.uid;
})
