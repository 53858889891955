import { Dispatch } from 'react';
import { GAME_PROVIDER_NAME, PLATFORM } from '../../../common/constants/game-constants';
import { getRegion } from '../../../common/helpers/cookies';
import { providerToGameSpecificProvider } from '../../../common/helpers/gameConfigsHelper';
import { setGameContext } from '../gameContext';
import { types } from '../../types';
import { GameInfo, GameWindowData } from './types';
import { setJackpotData } from '../../reducers/site-wide-jackpot/site-wide-jackpot';

export const dispatchGameContext = (
    dispatch: Dispatch<unknown>,
    platform: string,
    gameInfo: GameInfo,
    gameUid?: string
) => {
    if (gameInfo?.id_native === '-1') {
        dispatch(
            setGameContext({
                nativeId: gameInfo.id_native,
                gameLoaded: true
            })
        );
    }

    if (!gameInfo || !gameUid) {
        return;
    }

    const providerRef = platform === PLATFORM.Web ? gameInfo?.rgs_code_desktop : gameInfo?.rgs_code_mobile;

    dispatch(
        setGameContext({
            brand: process.env.BRAND?.toLowerCase(),
            rgi: gameInfo?.rgi?.toLowerCase(),
            ganId: gameInfo?.ganid,
            provider: providerToGameSpecificProvider(gameInfo?.provider?.providerName),
            providerRef: providerRef ?? gameInfo?.rgs_code_desktop,
            region: getRegion(),
            gameName: gameInfo?.seo_game_description_headline,
            gameId: gameInfo?.documentId || gameUid,
            gameUid
        })
    );
};

export const dispatchAmplitudeExperiments = (dispatch: Dispatch<unknown>, data: GameWindowData) => {
    dispatch({
        type: types.SET_AMPLITUDE_EXPERIMENTS_LOADED,
        payload: true
    });

    dispatch({
        type: types.SET_AMPLITUDE_EXPERIMENTS,
        payload: data?.experiments
    });
};

export const dispatchGameWindow = (dispatch: Dispatch<unknown>, data: GameWindowData) => {
    dispatch({
        type: types.GET_GAME_WINDOW_RECEIVED,
        payload: data?.gameWindow
    });
};

export const dispatchGameOverallStatus = (dispatch: Dispatch<unknown>, gameInfo: GameInfo, provider: string) => {
    if (provider === GAME_PROVIDER_NAME.INCENTIVE_GAMES) {
        return;
    }

    dispatch(
        setGameContext({
            overallStatus: gameInfo.status_overall
        })
    );
};

export const dispatchJackpotData = (dispatch: Dispatch<unknown>, data: GameWindowData) => {
    const jackpotData = data?.gameWindow?.jackpotData;

    dispatch(setJackpotData(jackpotData))
};

export const dispatchDirectLaunchParams = (dispatch: Dispatch<unknown>, gameInfo: GameInfo) => {
    const launchParamsSlice = gameInfo?.body.find(slice => slice.slice_label === 'game_launch_params')?.primary;

    dispatch(
        setGameContext({
            directLaunchParams: launchParamsSlice
        })
    );
};

export const escapeGameUid = (gameUid: string) => {
    const matches = gameUid.match(/^[^?&]+/g);

    return matches.length > 0 ? matches[0] : gameUid;
};
