import { useContext } from 'preact/hooks';
import ExitPopup from '../../../modal/exit-popup/exit-popup';
import { LicenseCard } from '../../../modal/license-card/LicenseCard';
import Panel from '../Panel/Panel';
import { GameWindowContext } from '../../GameWindow';
import ExitPopupRecentlyPlayed from '../../../modal/exit-popup/exit-popup-recently-played';
import { GameWindowErrorPopup } from '../../../modal/game-window-error/GameWindowErrorPopup';
import { useSelector } from 'react-redux';
import { swjEnabledSelector, swjJackpotIdSelector } from '../../../../selectors/site-wide-jackpot';

const Layout = ({ children }) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    const { isPortrait, dismissErrorMessage, exitGame, disableGameWindow, chooseDialog } = useContext(GameWindowContext);
    const isRewardsMachine = useSelector(state => state?.gameWindow?.isRewardsMachine);
    const swjToggle = useSelector(swjEnabledSelector);
    const { swjEnabled } = useSelector(state => state?.amplitudeExperiments?.experiments);
    const jackpotId = useSelector(swjJackpotIdSelector);
    const isSwjEnabled = swjToggle && swjEnabled === 'site-wide-jackpots';

    const positions = {
        first: isPortrait ? 'top' : 'left',
        second: isPortrait ? 'bottom' : 'right',
        swjEnabled: isPortrait ? null : 'top swj'
    };

    return (
        <div
            id="game-launcher-wrapper"
            className={`game-launcher-wrapper ${isPortrait ? 'portrait' : ''}`}
            style={{ display: 'flex', height: '100%', width: '100%', flexDirection: isPortrait ? 'column' : 'row' }}
        >
            <ExitPopup />
            <LicenseCard isPortrait={isPortrait} />
            <ExitPopupRecentlyPlayed />
            {!disableGameWindow && <GameWindowErrorPopup handlers={{ dismissErrorMessage, exitGame, chooseDialog }} />}
            {!isRewardsMachine && !disableGameWindow &&
            <Panel
                position={positions.first}
                jackpotId={jackpotId}
                isSwjEnabled={isSwjEnabled}
            />}
            <div className="center-container">
                {!isRewardsMachine && !disableGameWindow &&
                  jackpotId && isSwjEnabled &&
                    <Panel
                        position={positions.swjEnabled}
                        jackpotId={jackpotId}
                        isSwjEnabled={isSwjEnabled}
                    />}
                {children}
            </div>
            {!isRewardsMachine && !disableGameWindow &&
            <Panel
                position={positions.second}
                jackpotId={jackpotId}
                isSwjEnabled={isSwjEnabled}
            />}
        </div>
    );
};

export default Layout;
