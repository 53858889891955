export const JackpotsField = ({tiers, currentIndex}) => {
    require(`./styles/${process.env.APPLICATION}/style.scss`);
    const tier = tiers[currentIndex];

    return (
        <div className="swj-jackpot-wrapper">
            <div className={"swj-jackpot"}>
                <span className="swj-jackpot-type">{tier.name.toUpperCase()}</span>
                <span className={"swj-jackpot-value-single"}>
                    {`$${tier.currentAmount}`}
                </span>
            </div>
        </div>

    );
}
